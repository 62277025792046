.app {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  /* background-color: #BFEBF4; */
  background-image: linear-gradient(#24301f 40%, #e7faea);
  /* background-image: url(./components/pictures/Blue-Purple_background.png);
  background-attachment: fixed;
  background-position: center;  */
  min-height: 100vh;
  height: 100%;

  padding-bottom: 0%;
  margin-bottom: 0;
  
}

.App {

}

@font-face {
  font-family: honk;
  src: url(./components/fonts/Honk-Regular-VariableFont_MORF\,SHLN.ttf);
}

@font-face {
  font-family: bitter;
  src: url(./components/fonts/Bitter-VariableFont_wght.ttf);
}

@font-face {
  font-family: minecraft;
  src: url(./components/fonts/Minecraft.ttf);
}

@font-face {
  font-family: superior;
  src: url(./components/fonts/LTSuperior-Semibold.otf);
}

@font-face {
  font-family: bubbl;
  src: url(./components/fonts/Bubbl-ExtraBold.otf);
}

@font-face {
  font-family: teacher-regular;
  src: url(./components/fonts/Teachers-Regular.woff);
}

@font-face {
  font-family: teacher-semibold;
  src: url(./components/fonts/Teachers-SemBd.woff);
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50% { transform: translate(0, 15px); }
  100% { transform: translate(0, -0px); }    
}

a { text-decoration: none; }

p {
  font-family: teacher-regular;
  font-size: larger;
}

h1 { font-family: teacher-semibold; font-weight: bold;}

h2 { font-family: teacher-regular; }

button:hover{ cursor: pointer; }

.navbar {
  background: white;
  /* padding: 0.2rem calc((100vw - 1000px) / 2); */
  padding: 1% 1% 0.25%;
  max-height: clamp(4rem, 5vw, 7rem);
  z-index: 12;
  position: sticky; 
  top: 0;
  display: flex;
  flex: auto;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* width: 100%; */

  .logo-container {
    align-content: center;
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 1%;
    padding-right: 5%;
    max-width: clamp(10rem, 10vw, 15rem);
    min-width: 8rem;
    align-self: center;
  }
  
  .logo-container img {
    align-self: center;
    width: 100%;
  }
  
  .links {
    align-items: flex-end;
    padding: 3rem 1rem 1rem; /* top, x, bottom */
    justify-content: space-around;
    display: flex;
  
    .link {
      cursor: pointer;
      color: #4da99e;
      font-family: teacher-semibold;
      font-weight: bold;
      text-transform: uppercase;
      /* font-variant: small-caps; */
      text-decoration: none;
      height: clamp(1rem, 3vw, 5rem);
      font-size: clamp(1.25rem, 1.75vw, 2rem);
      padding: 0 clamp(0.5rem, 0.75vw, 1rem);
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
      }
    .link:hover {
      color: #83ddcd;
      /* Change text color on hover (you can choose any color you like) */
    }

    .apply {
      height: clamp(2rem, 3vw, 8rem);
      background-color: #fefeaa;
      font-size: fit-content;
      color: rgb(255, 126, 74);

      padding: 0.25rem 0.5rem;
      /* text-shadow: 0px 1px 0px #000; */
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      border-color: #f5c499;
      border-style: solid;
      border-width: 0.2vw;
    }

    .apply:hover {
      color: rgb(224, 113, 68);
    }

    .mlh-banner-mobile {
      display: none;
    }
  }

  .dynamic {
    align-items: flex-end;
    padding: 3rem 1rem 1rem; /* top, x, bottom */
    justify-content: space-around;
    display: flex;
    padding: 0;
    column-gap: 2rem;
  }

  .lessSpace {
    column-gap: 0rem;
  }

  .nav-btn {
    display: none;
    visibility: hidden;
    opacity: 0;

    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1.8rem;
  }

}

.Page {
  margin: 3% 5%;
  background-color: white;
  margin-top: 2rem;
  padding: 2rem 2rem;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.margins {
  margin: 3% 5%;
}

.banner-container {
  display: flex;
  align-content: center;
  justify-content: center;
}

.banner {
  position: relative;
  top: 0;
  left: 0;
  max-width: 100%;

}

.info-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.home-location {
  align-self: center;
  text-align: center;
  font-family: bitter;
  font-variant: small-caps;
  font-size: clamp(1rem, 2vw, 2rem);
  color: #4da99e;
  max-width: 600px;
}

@import "~react-image-gallery/styles/css/image-gallery.css";

.Gallery {
  min-height: 35vh;
  max-height: 60%;
}

.image-gallery img {
  object-fit: contain;
}

.image-gallery-slide img {
  max-width: 100%;
}

.Home { 

  .home-buttons {
    font-family: teacher-semibold;
    text-transform: uppercase;
    margin: 1% 5%;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  
  .socials {
    padding-top: 0%;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
  
    .icons {
      padding: 0rem clamp(0.5rem);
      color: #cda95f;
      font-size: 5rem;
    }
  
    .icons:hover {
      border-radius: 5px;
      color: #ffd175;
      cursor: pointer;
    }
  }

  .welcome-container { 
    display: flex; 

    .floating-container {
      padding-top: 2rem;
      align-content: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      width: 85%;
    }

    .home-title {
      align-self: center;
      font-family: bitter;
      font-variant: small-caps;
      font-size: clamp(2rem, 4vw, 4rem);
      color: #4da99e;
      max-width: 800px;
      animation-name: floating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }

    .logo-home-container {
      position: relative;
      display: flex;
      justify-content: center;
    }

    .logo-home {
      padding-bottom: 2rem;
      /* max-height: clamp(10rem, 15vw, 22rem); */
      max-width: clamp(20rem, 50vw, 50rem);
      animation-name: floating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }

    .logo-home:hover { animation-duration: 1s; }

    .home-date {
      align-self: center;
      text-align: center;
      font-family: bitter;
      font-variant: small-caps;
      font-size: clamp(1rem, 3vw, 3rem);
      font-style: bold;
      color: #cda95f;
      -webkit-text-stroke: #7b7144;
      max-width: 600px;
    }

    .welcome-right-container {
      position: relative;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-content: center;
      padding-top: 10%;
      padding-bottom: 2.5%;
      padding-right: 0.75rem;
    }
  }

}

.Button{
  align-self: center;
  text-align: center;
  border:none;
  padding: 1rem 1rem;
  border-radius: 0.2rem;
  display: inline-block;
  margin: 0.2rem;
  text-decoration: none;
  font-family: teacher-semibold;
  text-transform: uppercase;
  font-size: clamp(1rem, 2vw, 2rem);
  font-variant: small-caps;

  color: white;
  width: clamp(4rem, 25vw, 25rem);
  /* height: clamp(3rem, 5vw, 7rem); */
  height: clamp(5rem, 7vw, 9rem);
  margin-bottom: clamp(1rem, 3vw, 5rem);
}

.Button-home {
  position: relative;
  filter: dropshadow(color=#000, offx=0px, offy=1px);
  border-radius: 5px;
  border-style: solid;
  border-width: 0.35vw;
  padding: 0.5rem 0.5rem;
  font-family: teacher-semibold;
  text-transform: uppercase;
}

.Button-interest{
  background-color: #4da99e;
  -webkit-box-shadow:inset 0 1px 0 #4da99e, 0 10px 0 #00424b;
  border-color: #22457d;
}

.Button-interest:hover{
  top:10px;
  background-color: rgb(13, 99, 137);
  -webkit-box-shadow:inset 0 2px 0 #c4eaff, inset 0 0px 0 #00424b;
}

.Button-apply{
  background-color: #fefeaa;
  color: black;
  -webkit-box-shadow:inset 0 1px 0 #fefeaa, 0 10px 0 #f4c671;
  border-color: #f5c499;
}

.Button-apply:hover{
  top:10px;
  background-color: rgb(255, 218, 132);
  -webkit-box-shadow:inset 0 2px 0 #fff7df, inset 0 0px 0 #ffca92;
}

.Button-sponsor{
  background-color: #F07167;
  -webkit-box-shadow:inset 0 1px 0 #F07167, 0 10px 0 #441305;
  border-color: #712020;
}

.Button-sponsor:hover{
  top:10px;
  background-color: #af4445;
  -webkit-box-shadow:inset 0 2px 0 #ffcbcb, inset 0 0px 0 #ba3d1b;
}

.Button-mentor{
  background-color: #fefeaa;
  color: black;
  -webkit-box-shadow:inset 0 1px 0 #fefeaa, 0 10px 0 #f4c671;
  border-color: #f5c499;
}

.Button-mentor:hover{
  top:10px;
  background-color: rgb(255, 218, 132);
  -webkit-box-shadow:inset 0 2px 0 #fff7df, inset 0 0px 0 #ffca92;
}



.About {
  .mission-container {
    display: inline;
    color: #4da99e;
    font-family: teacher-regular;
    font-size: clamp(0.5rem, 1rem, 1rem);
  }

  .little-guy {
    height: 100px;
    position: absolute;
    right: 20%;
    margin-top: -8%
  }
  
  .who-we-are-container{
    padding-top: 0;
    display: flex;
    column-gap: 2rem;

    .who-we-are-child-container {
      flex: 1;
    }

    .collaborators-container{
      justify-items: center;
      align-items: center;
      padding-bottom: 0;
      padding-top: 0%;
    }
      
    .logo-who-we-are{
      width: 75%;
      height: auto;
      padding-left: 10%;
    }
  }

  .location-container{
    display: flex;
    flex-direction: column-reverse;
    column-gap: 10rem;
    align-items: center;

    .location-child-container{
      flex: 2;
      text-align: center;
      margin: 0 10rem;
      
    }
  }

  .map iframe{
    height: 18.75rem;
    width: 50rem;
  }
  

  .team-container{
    padding: 1rem;
    display: flex;
    width: 100%;

    .team-child-container-names {
      margin-right: 4%;

      .btn-committee-names {
        max-width: 138px;
        font-family: teacher-semibold;
        flex: 2;
      }
      .btn-committee-names button{
        background-color: #cda95f;
        border:0.5px solid #7b7144;
        font-family: teacher-semibold;
        color: white;
        a { color: white; }
        text-align: center;
        cursor:pointer;
        padding: 0.75rem 1.5rem;
        display: block;
        width: 100%;
        
      }

      .btn-committee-names:not(:last-child){
        border-bottom: none;
      }

      .btn-committee-names button:hover{
        background-color: #7b7144;
      }
    }

    .team-child-container-members {
      display: flex;
      justify-content: center;
      width: 100%;

      .members img {
        width: 100%;
        height: auto;
        display: block;
      }
      
      .members{
        margin: 0;
        overflow: scroll;
        overflow-x: hidden;
        padding: 0;
        max-height: 100%;
        width: 100%;
        height: 389px;
      } 
      
      .members li{
        float: left;
        width: 100%;
        list-style: none;
      }
      
      @media (min-width: 28.75em) {
        .members li {
          width:50%;
          min-width: 150px;
          max-width: 174px;
        }
      }
    }
  }
}

figure {
  margin: 0 1.25em 1.25em 0;
  position: relative;
  text-align: center;
  border: 0.3em solid  #cda95f;
  border-radius: 0.5em;
  overflow: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;   /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
figure::-webkit-scrollbar {
  display: none;
}


figure:hover{
  border: 0.4em solid #7b7144;
  img {
    opacity: 0.1;
    transition: all .2s ease-in-out .2s;
  }
  figcaption {
    top: 50%;
    margin-top: -5em;
    font-size: 0.8rem;
  }

  h3, h4 {
    font-size: 0.8rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
}

figcaption{
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  transition: all 0.2s ease-in-out allow-discrete;
  padding-top: 8%;
}

figcaption a {
  font-weight: bold;
  text-decoration: none;
  background-color: #cda95f;
  display: inline-block;
  text-align: center;
  color: white;
  border-radius: 1.25rem;
  padding-top: 0.5em;
  width: 90%;
  height: 1.5rem;
  margin-bottom: 8%;
  font-family: teacher-semibold;


  &:hover{
    background-color: #7b7144;
  }
}

.Button-sponsor-page{
  background-color: #ff8178;
  padding: 1rem 1rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-shadow: 0px 1px 0px #000;
  filter: dropshadow(color=#000, offx=0px, offy=1px);
  position: relative;
  
  -webkit-box-shadow:inset 0 1px 0 #ffcbcb, 0 10px 0 #441305;
  /* -moz-box-shadow:inset 0 1px 0 #c4eaff, 0 10px 0 #00424b;
  box-shadow:inset 0 1px 0 #c4eaff, 0 10px 0 #00424b; */
  
  border-radius: 5px;
  border-color: #712020;
  border-style: solid;
  border-width: 0.35vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(9rem, 40vw, 30rem);
  height: clamp(4rem, 6vw, 10rem);
  margin-top: 2%;
}

.Button-sponsor-page:hover{
  top:10px;
  background-color: rgb(255, 129, 120);
  -webkit-box-shadow:inset 0 2px 0 #ffcbcb, inset 0 0px 0 #ba3d1b;
}

.sponsor-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1%;
}

.sponsor-tier-container {
  margin-right: 4%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sponsor-logo-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.sponsor-logo-container a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16vw;
  max-height: 100%;
  background-color: white;
  object-fit: contain;
  margin: 0.5rem;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 7px;
  height: 8vh;
}

.sponsor-logo-container img {
  max-width: 80%;
  max-height: 100%;
  object-fit: contain;
}

@media (max-width: 600px) {

  .sponsor-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1%;
  }

  .sponsor-logo-container a {
    height: 6vh;
    width: 25vw;
    padding: 1rem;
  }

  .sponsor-logo-container img {
    max-width: 100%;
    max-height: 100%;
  }
}

.arrow { padding-right: 1rem; }

.FAQ {
  padding-bottom: 0%;

  .faq-collapse-container {
    font-size: 1.25rem;
    font-weight: 550;
    border: none;
    background: #98d1cb;
    /* font-weight: bold; */
    font-family: bitter;
    margin-bottom: 2rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    
  }
  
  .faq-collapse {
    border: none;
    padding-left: 2rem;
    display: flex;
    font-family: teacher-regular;
    font-weight: 550;
    padding-top: 0.3rem;
  }
}

/* .app.page-login {
  border: 5px solid white;
  background-image: linear-gradient(#24301f 100vh, #f4f7ff);
} */

.login-container {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 1rem 2rem;
  align-self: center;
  align-items: center;
  margin-inline: 10%;
  margin-top: 5%;
  border-radius: 25px;
  min-height: 60vh;

  .form-container {
    /* background-color: beige; */
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .loginBubbles {
      color: rgb(38, 50, 56);
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 1px;
      background: rgba(136, 126, 126, 0.04);
      padding: 10px 20px;
      border: none;
      border-radius: 20px;
      outline: none;
      box-sizing: border-box;
      border: 2px solid rgba(0, 0, 0, 0.02);
      margin-bottom: 50px;
      text-align: center;
      margin-bottom: 27px;
      font-family: 'Ubuntu', sans-serif;
    }

    .submitBubble {
      color: rgb(38, 50, 56);
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 1px;
      background-color: #cda95f;
      padding: 10px 20px;
      border: none;
      border-radius: 20px;
      outline: none;
      box-sizing: border-box;
      border: 2px solid rgba(0, 0, 0, 0.02);
      margin-bottom: 50px;
      text-align: center;
      margin-bottom: 27px;
      font-family: 'Ubuntu', sans-serif;
      cursor: pointer;
      
    }
    .submitBubble:hover {
      background-color: #7b7144;
      color: white;
      flex-direction: column;
      align-self: center;
    } 
  }

  .image {
    align-self: center;
    width: 8%;
    position: absolute;
  }

  .little-guy1 {right: 18%;}
  .little-guy2 {left: 17%;}
  .little-guy3 {left: 12%; margin-top: -60px;}
  .little-guy4 {right: 12%; margin-top: -60px;}
}

.Portal {
  min-height: 70vh;
  padding: 0;
  
  .apply {
    height: clamp(2rem, 5vw, 8rem);
    background-color: #fefeaa;
    font-size: fit-content;
    color: rgb(255, 126, 74);
  
    padding: 0.25rem 0.5rem;
    /* text-shadow: 0px 1px 0px #000; */
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border-color: #f5c499;
    border-style: solid;
    border-width: 0.2vw;
  }

  .name-banner {
    background-color:  #98d1cb;
    display: flex;
    flex: auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding-inline: 2rem;
    max-height: 10%;
    overflow: hidden;
    padding-bottom: 2%;

    .message {
      text-align: left;
      color: rgb(34, 34, 56);
      font-family: teacher-regular;
      font-size: 1.2rem;

      .welcome-message {
        color: white;
        font-size: 3.5rem;
        margin-bottom: 0;
      }
    }


    .character {
      align-self: center;
      float: inline-end;
      display: flex;
      padding-bottom: 2%;
      padding-top: 2%;
      padding-left: 5%;
      width: clamp(5rem, 10rem, 15rem);
      height: auto;
      margin-left: auto; 
      margin-right: 5%;
      animation-name: floating;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }

  .disabledButton-rsvp {
    color: rgb(81, 86, 89);
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    background-color: rgb(143, 141, 141);
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    text-align: center;
    margin-bottom: 27px;
    font-family: 'Ubuntu', sans-serif;
    cursor: pointer;
    margin-top: 1rem;
  }

  .Button-rsvp {

    color: rgb(38, 50, 56);
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    background-color: #cda95f;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    text-align: center;
    margin-bottom: 27px;
    font-family: 'Ubuntu', sans-serif;
    cursor: pointer;
    margin-top: 1rem;
    
  }
  .Button-rsvp:hover {
    background-color: #7b7144;
    color: white;
    flex-direction: column;
    align-self: center;
  } 

  
  .portal-padding {
    padding-inline: 5%;
    padding-bottom: 5%;
  }

}

.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(44, 63, 90, 0.6);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 13;
  top: 0;

  .modalContainer {
    justify-self: center;
    width: clamp(20rem, 35rem, 35rem);
    height: clamp(20rem, 28rem, 30rem);
    border-radius: 12px;
    border-image: linear-gradient(rgb(195, 149, 210), rgb(116, 173, 207)) 27 / 2rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 5%;


    .little-guy {
      height: 130px;

      /* position: absolute;
      margin-top: -35px;
      margin-right: -30px; */
    }
  }

  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 1%;
    
  }
  .modalContainer .title h1 {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
    padding-top: 3%;
    padding-right: 3%;
  }

  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 30px;
    cursor: pointer;
  }
  .titleCloseBtn button:hover {
    color: #1b797e;
  }

  .modalContainer .body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  }

  .modalContainer .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: clamp(1rem, 1.5rem, 2rem);
    margin-bottom: 5%;
  }

  .apply {
    height: clamp(5rem, 5vw, 8rem);
    width: 70%;
    background-color: #fefeaa;
    font-size: 3rem;
    font-style: bold;
    font-family: teacher-semibold;
    color: rgb(255, 126, 74);
    padding: 0.25rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border-color: #f5c499;
    border-style: solid;
    border-width: 0.2vw;
  }

  .apply:hover {
    color: rgb(207, 59, 0);
  }
}

.application-banner {
  text-align: center;
  padding: 0.5%;
  background: #fefeaa;
  color: black;
  border-color: rgb(206, 229, 238);
  border-width: 0.1px;
  border-bottom-style: solid;
  font-family: teacher-regular;
  font-size: 1.2rem;
}

.align-right {
  margin-right: 0;
  margin-left: auto;
}

.disabledButton {
  color: rgb(81, 86, 89);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  background-color: rgb(143, 141, 141);
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  outline: none;
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0.02);
  margin-bottom: 50px;
  text-align: center;
  margin-bottom: 27px;
  font-family: 'Ubuntu', sans-serif;
  cursor: pointer;
}

.padding {
  margin: 4%;
}

.button-glow{
  color: #fff;
  padding: 16px 34px;
  box-shadow: 0 0 0 0 rgb(255, 126, 74);
  -webkit-animation: glow 2s linear infinite;
          animation: glow 2s linear infinite
}

.button-glow-home-apply{
  color: black;
  padding: 16px 34px;
  box-shadow: 0 0 0 0 rgb(255, 126, 74);
  -webkit-animation: glow 2s linear infinite;
          animation: glow 2s linear infinite
}

@-webkit-keyframes glow {
  0% {
    box-shadow: 0 0 0 0 rgb(255, 126, 74);
  }
  50% {
    box-shadow: 0 0 30px 0 rgb(255, 126, 74);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0 0 rgb(255, 126, 74);
  }
  50% {
    box-shadow: 0 0 25px 0 rgb(255, 126, 74);
  }
}
.countdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-align: center;
  padding-top: 0.5rem;
}

.countdown-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.countdown-text {
  font-family: teacher-semibold;
  margin-right: 10px;
  font-size: 25px;
  color: #000;
}

.countdown-item {
  font-family: teacher-semibold;
  display: inline-block;
  margin: 0 10px;
}

.countdown-label {
  font-family: teacher-regular;
  font-size: 14px;
  color: #888;
}

.colon {
  display: inline-block;
  vertical-align: middle;
  /* padding-right: 20px;  */
}

.mlh-banner {
  display:block;
  max-width:85px;
  min-width:60px;
  position:absolute;
  right:50px;
  /* top: clamp(4.75rem, 5.5vw, 7rem); */
  width:10%;
  z-index:11;
}

.footer-container {
  display: absolute;
  bottom: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  text-align: center;
  padding: 2rem;
  padding-top: 4rem;
  margin-inline: 5rem;
  font-family: teacher-regular;

  .divider {
    border: 0;
    opacity: 0.6;
    border-bottom: 0.0625rem solid;
    margin-bottom: 0.2in;
    border-color: #00424b;

  }

  .footer-content {
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    align-items: center;
    justify-content: center;

    .image {
      height: 8rem;
      align-self: center;
    }

    .column {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      border-left-color: #000;
    }
  }  
  .link {
    font-weight: bold;
    text-decoration: none;
    color: #cda95f;
    height: 15px;
  }

  .socials {
    padding-top: 2%;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
  }
  .socials .icons {
    padding: 0rem clamp(0.5rem);
    color: #cda95f;
    font-size: 2rem;
    text-align: left;
    float: left;
  }

  .socials .icons:hover {
    border-radius: 5px;
    color: #7b7144;
    cursor: pointer;
  }

}

/* Admin Portal JS */
.email-send-scroll {
  width: 70%; 
  height: 500px;
  overflow-x: auto;
}

.email-send-scroll table {
  margin-top: 3rem;
  border: 1px solid rgb(0, 0, 0);

}

.email-send-scroll tbody {
  border-bottom: 1px solid rgb(0, 0, 0);
}

.email-send-scroll th {
  border-bottom: 1px solid rgb(5, 5, 5);
  border-right: 1px solid rgb(6, 6, 6);
  padding: 2px 4px;
  text-align: left;
}

.email-send-scroll td {
  border-bottom: 1px solid rgb(6, 6, 6);
  border-right: 1px solid rgb(4, 4, 4);
  padding: 2px 4px;
}



/* Schedule JS */
.disclaimer {
  font-size: 20px;
}

.tables-container {
  /* putting contents of table side-by-side */
  display: flex;
  justify-content: space-around;
}

.backgroundSchedule {
  background-color: rgba(0, 177, 196, 0.1);
  border-radius: 10px;
  width:45%;
}

table {
  /* background color opacity changed but text of table remains visible */
  opacity: 1; 
  margin-left: 15px;
  padding: 20px;
  padding-bottom: 10px;
}

.locationEvent{
  font-size: 12px;
  color: purple;
}

.presenter {
  font-size: 13px;
  font-style: italic;
}

.time {
  /* controls spacing btwn time & event */
  padding-right: 40px;
}

th {
  font-style: italic;
  text-decoration: underline;
}

.time, .eventDescription, th {
  /* controls the text being spaced evenly */
  vertical-align: middle;
  padding-bottom: 10px;
}

.content-all {
  font-family: teacher-semibold;
  margin-bottom: 0px;
  border-bottom: 5px rgb(132, 132, 132) solid;
  border-left: 4px rgb(132, 132, 132) solid;
  border-right: 4px rgb(132, 132, 132) solid;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tabs {
  display: flex;
  justify-content: space-between;
}

.tab {
  font-family: teacher-semibold;
  font-size: 18px;
  /* padding: 10px; */
  padding: 20px 1px 20px;
  flex-grow: 1;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.tab.active {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top: 4px rgb(132, 132, 132) solid;
  border-left: 4px rgb(132, 132, 132) solid;
  border-right: 4px rgb(132, 132, 132) solid;
  border-bottom: 0px rgb(132, 132, 132) solid;
}

/* .tab:nth-child(1).active + .tab,
.tab:nth-child(1).active + .tab + .tab {
  border-bottom: 5px black solid; 
}

.tab:nth-child(2).active {
  .tab:nth-child(1) {
    border-bottom: 5px black solid; 
  }
} */

.tab:nth-child(1):hover,
.tab:nth-child(1).active {
  background-color: #FFB4B0;
}

.tab:nth-child(2):hover,
.tab:nth-child(2).active {
  background-color: #FFFFD9;
}

.tab:nth-child(3):hover,
.tab:nth-child(3).active {
  background-color: rgb(211, 228, 255);
}

.tab:nth-child(1).active, .content1{
  background-color: #ffc3c0
}

.tab:nth-child(2).active, .content2 {
  background-color: #FFFFD9;
}

.tab:nth-child(3).active, .content3 {
  background-color: rgb(211, 228, 255);
}

.RSVP {
  margin-inline: 10%;
  /* background-color: black; */
  

  #msform {
    max-width: 725px;
    margin: 4% auto;
    text-align: center;
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  #msform fieldset {
    background: white;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
    padding: 7% 13%;
    box-sizing: border-box;
    width: 80%;
    margin: 0 auto;
    
    /*stacking fieldsets above each other*/
    position: relative;
  }

  #msform fieldset:not(:first-of-type) {
    display: none;
  }

  .noDisplay {
    display: none;
  }

  /*Hide all except first fieldset*/

  
  
  /*inputs*/
  #msform input, #msform textarea {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 10px;
    box-sizing: border-box;
    color: #2C3E50;
    font-size: 14px;
  }
  #msform textarea {width: 100%;}


  .mealPref {
    display: flex;
    flex-direction: column;
    /* input {
      width: 0%;
    } */
  }
  
  .mealOption {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Adjust vertical spacing between options */
  }
  
  .mealOption input{
    margin-right: 20px; /* Adjust horizontal spacing between checkbox and label */
    width: auto;
    cursor: pointer;
  }
  
  .mealOption label {
    margin: 0; /* Reset margin to prevent the label from aligning to the right */
    text-align: left;
  }
  .diet {
    font-size: 1rem;
    padding-bottom: 4%;
  }

  /*buttons*/
  #msform .action-button {
    width: 100px;
    background: #cda95f;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 1px;
    cursor: pointer;
    padding: 10px;
    margin: 10px 5px;
    text-decoration: none;
    font-size: 14px;
  }
  #msform .action-button:hover, #msform .action-button:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #7b7144;
  }
  /*headings*/
  .fs-title {
    font-size: 18px;
    text-transform: uppercase;
    color: #2C3E50;
    margin-bottom: 10px;
  }

  .congrats {
    color: #4da99e;
    font-size: 2rem;
  }

  .fs-subtitle {
    font-weight: normal;
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
  }
  /*progressbar*/
  #progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    /*CSS counters to number the steps*/
    counter-reset: step;
    padding-left: 0;
  }
  #progressbar li {
    list-style-type: none;
    color: white;
    text-transform: uppercase;
    font-size: 9px;
    width: 25%;
    float: left;
    position: relative;
  }
  #progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 20px;
    line-height: 20px;
    display: block;
    font-size: 10px;
    color: #333;
    background: white;
    border-radius: 3px;
    margin: 0 auto 5px auto;
  }
  /*progressbar connectors*/
  #progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: #666; /* progress bar */
    position: absolute;
    left: -50%;
    top: 9px;
    z-index: -1; /*put it behind the numbers*/
  }
  #progressbar li:first-child:after {
    /*connector not needed before the first step*/
    content: none; 
    
  }
  /*marking active/completed steps green*/
  /*The number of the step and the connector before it = green*/
  #progressbar li.active:before,  #progressbar li.active:after{
    background: #4da99e;
    color: white;
  }

}

.tech-support{
  font-size: 1rem;
  max-width: 70%;
}

/* .action-items {

  display: cen;
  text-align: center;
  margin: 0 auto; 
  max-width: 10%;
} */


/* Schedule JS */
.disclaimer {
  font-size: 20px;
}

.tables-container {
  /* putting contents of table side-by-side */
  display: flex;
  justify-content: space-around;
}

.backgroundSchedule {
  background-color: rgba(0, 177, 196, 0.1);
  border-radius: 10px;
  width:45%;
}

table {
  /* background color opacity changed but text of table remains visible */
  opacity: 1; 
  margin-left: 15px;
  padding: 20px;
  padding-bottom: 10px;
}

.location{
  font-size: 12px;
  color: purple;
}

.presenter {
  font-size: 13px;
  font-style: italic;
}

.time {
  /* controls spacing btwn time & event */
  padding-right: 40px;
}

th {
  font-style: italic;
  text-decoration: underline;
}

/* .eventDetails {
  display: flex;
  flex-direction: column;
} */

.time, .eventDescription, .eventLocation,th {
  /* controls the text being spaced evenly */
  vertical-align: middle;
  padding-bottom: 10px;
  padding-right: 50px;
}


/* setting up font style & formation for fri-sun content */
.content-all {
  font-family: teacher-semibold;
  margin-bottom: 0px;
  border-bottom: 5px rgb(132, 132, 132) solid;
  border-left: 4px rgb(132, 132, 132) solid;
  border-right: 4px rgb(132, 132, 132) solid;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.formatPreSchedule {
  font-family: teacher-semibold;
  margin-bottom: 20px;
  border-left: 4px rgb(132, 132, 132) solid;
  border-bottom: 4px rgb(132, 132, 132) solid;
  border-right: 4px rgb(132, 132, 132) solid;
}

.contentPreSchedule {
  background-color: #ffcabb;
}

.tabs {
  display: flex;
  justify-content: space-between;
}

.tab {
  color: black;
  font-family: teacher-semibold;
  font-size: 18px;
  padding: 10px;
  flex-grow: 1;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.prescheduleTab{
  width: 100%;
  justify-content: center;
  pointer-events: none;
}

.tab.active {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top: 4px rgb(132, 132, 132) solid;
  border-left: 4px rgb(132, 132, 132) solid;
  border-right: 4px rgb(132, 132, 132) solid;
  border-bottom: 0px rgb(132, 132, 132) solid;
}

.tab:nth-child(1):hover,
.tab:nth-child(1).active {
  background-color: #FFB4B0;
}

.tab:nth-child(2):hover,
.tab:nth-child(2).active {
  background-color: #FFFFD9;
}

.tab:nth-child(3):hover,
.tab:nth-child(3).active {
  background-color: rgb(211, 228, 255);
}

.tab:nth-child(1).active, .content1{
  background-color: #ffc3c0
}

.tab:nth-child(2).active, .content2 {
  background-color: #FFFFD9;
}

.tab:nth-child(3).active, .content3 {
  background-color: rgb(211, 228, 255);
}

.Awards{
  .product-image {
    width: 95%;
    /* min-height: 10rem; */
    height: 15rem;
    padding: 1%;
    /* height: 20em; */
    object-fit: contain;
  }
  
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    background-color: #e7faea;
    max-width: 320px;
    height: 35rem;
    margin: 1rem 1rem;
    text-align: center;
  }

  .content {
    padding: 1% 5%;
  }

  .title {
    font-family: teacher-semibold;
  }
  
  .description {
    color: grey;
    font-size: 20px;
    font-family: teacher-regular;
  }
  
  .card button {
    border: none;
    outline: 0;
    padding: 12px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
  }
  
  .card button:hover {
    opacity: 0.7;
  }

}


.carousel-item-container {
  display: inline-flex;
  width: 33.33%; /* Set the width to 33.33% for displaying three cards at a time */
  float: left;
}


.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #4da99e; 
  border-radius: 45%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 30em;
  height: 15em;
}

.carousel-indicators li {
  background-color: #4da99e; 
}







