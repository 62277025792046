@media only screen and (max-width: 430px) {
  .login-container {
    .form-container {
      .loginBubbles {
        font-weight: 500;
        font-size: 10px;
      }
    }
  }

  figure:hover{
    border: 0.5em solid #7b7144;
    img {
      opacity: 0.1;
      transition: all .2s ease-in-out .2s;
    }
    figcaption {
      top: 50%;
      margin-top: -5em;
      font-size: 0.8rem;
    }
  
    h3  {
      font-size: 0.7rem;
    }
    h4 {
      font-size: 0.5rem;
    }
  }

  figcaption a {
    border-radius: 1.25rem;
    padding-top: 0.5em;
    width: 90%;
    height: 1.5rem;
  }

  .countdown-text {
    font-size: 16px;
  }

  .countdown-item {
    font-size: 16px;
  }
  .countdown-label {
    font-size: 12px;
  }
}

@media only screen and (max-width: 500px) {
  .app {
    min-height: 100vh;
    height: 100%;
    
  }

  .Portal {
    min-height: 70vh;
    padding: 0;
    
    .apply {
      height: clamp(4rem, 9vw, 10rem);
      width: 40%;
      font-size: 1.3rem;
    }
  
    .name-banner {
      display: flex;
      flex-direction: column;
  
      .message {
        .welcome-message {
          font-size: 2rem;
        }
      }
  
      .character {
        padding-left: 0%;
        padding-bottom: 4%;
        margin-left: auto; 
        margin-right: auto;
      }
    }
  }

  .countdown-text {
    font-size: 18px;
  }

  .countdown-item {
    font-size: 18px;
  }
  .countdown-label {
    font-size: 12px;
  }

  .footer-container {
    .socials .icons {
      font-size: 1.75rem;
    }
  }

}

@media only screen and (max-width: 556px) {

  @keyframes floating {
    0% { transform: translate(0,  0px); }
    50% { transform: translate(0, 10px); }
    100% { transform: translate(0, -0px); }    
  }

  .Home {
    background-color: white; 
    padding: 1rem 2rem;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 3% 5% 3%;

    .home-buttons {

      flex-direction: column;
      row-gap: 0.1rem;
      align-self: center;
      align-items: center;
      justify-content: center;
    }
  
    .socials {
      padding-top: 3%;  
      .icons {
        font-size: 3rem;
      }
    }

    .welcome-container {
      display: contents;

      .floating-container {
        padding-top: 2rem;
        margin: 0px;
        padding-right: 0px;
      }

      .home-title {
        font-size: clamp(1.5rem, 1.5vw, 3rem);
      }

      .logo-home {
        max-width: 110%;
      }

      .home-date {
        font-size: clamp(2rem, 4vw, 4rem);
        max-width: 800px;
      }

      .welcome-right-container {
        align-items: center;
        padding-right: 0;
        padding-bottom: 4.5%;
      }
    }
  }

  .Button{
    font-family: bitter;
    height: clamp(3rem, 5vw, 7rem);
  }

  button:hover{ cursor: pointer; }

  .Button-home {
    width: clamp(13rem, 13rem, 20rem);
  }

  .image-gallery-slide img {
    max-width: 100%;
    max-height: 25vh !important;
  }
  
  .About{    
    .logo-who-we-are{
      max-width: 75%;
    }
  }

  figure {
    margin: 0 1.25em 1.25em 0;
    position: relative;
    text-align: center;
    border: 0.5em solid #cda95f;
    border-radius: 0.5em;
  }

  figure:hover{
    border: 0.5em solid #7b7144;
    img {
      opacity: 0.1;
      transition: all .2s ease-in-out .2s;
    }
  }

  .Button-sponsor-page{
    background-color: rgb(255, 129, 120);
    padding: 1rem 1rem;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-shadow: 0px 1px 0px #000;
    filter: dropshadow(color=#000, offx=0px, offy=1px);
    position: relative;
    
    -webkit-box-shadow:inset 0 1px 0 #ffcbcb, 0 10px 0 #441305;
    /* -moz-box-shadow:inset 0 1px 0 #c4eaff, 0 10px 0 #00424b;
    box-shadow:inset 0 1px 0 #c4eaff, 0 10px 0 #00424b; */
    
    border-radius: 5px;
    border-color: #712020;
    border-style: solid;
    border-width: 0.35vw;
    display: flex;
    width: clamp(9rem, 40vw, 40rem);
    height: clamp(4rem, 6vw, 10rem);
  }
  .Button-sponsor-page div{
    font-size: 0.8rem;
  }


  .Button-sponsor-page:hover{
    top:10px;
    background-color: rgb(255, 129, 120);
    -webkit-box-shadow:inset 0 2px 0 #ffcbcb, inset 0 0px 0 #ba3d1b;
  }
}

@media only screen and (max-width: 1200px) {
  .About {
    .collaborators-container{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      align-items: center;
      gap: 1em;
      justify-content: center;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 975px) {
  .location-container{
    display: flex;

    .location-child-container{
      flex:1;
      text-align: left !important;
      margin: 0px 0px !important;
    }
  }
  .map iframe{
    visibility: hidden;
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .image-gallery-slide img {
    max-width: 100%;
    max-height: 50vh !important;
  }

  .members {
    max-height: 300px;
    overflow: scroll;
  }
  .committeeMembers-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    align-items: center;
    gap: 1em;
  }

  .navbar {

    /* move logo to center */
    .logo-container {
      align-content: center;
      padding-bottom: 2%;
      padding-top: 2%;
      padding-left: 1%;
      padding-right: 5%;
      max-width: clamp(8rem, 10vw, 15rem);
      /* max-width: clamp(6rem, 8vw, 15rem); */
      min-width: 6rem;
      align-self: center;
    }
    
    .logo-container img {
      align-self: center;
      width: 100%;
    }
    
    .links {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: fixed;
      top: 0;
      right: -100%;
      background-color: white;
      width: 80%;
      max-width: 35vh;
      height: 100vh;
      row-gap: 4%;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      padding: 5rem 1rem 1rem; /* top, x, bottom */

      transition: 0.4s;

      .link {
        color: #4da99e;
        font-family: teacher-semibold;
        font-weight: bold;
        font-variant: small-caps;
        text-decoration: none;
        height: clamp(1rem, 3vw, 5rem);
        font-size: clamp(1.25rem, 1.75vw, 2rem);
        padding: 0 clamp(0.5rem, 0.75vw, 1rem);
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
        }
      .link:hover {
        cursor: pointer;
        color: #83ddcd;
        /* Change text color on hover (you can choose any color you like) */
      }

      .apply {
        height: clamp(2rem, 3vw, 7rem);
        width: 100%;
        background-color: #fefeaa;
        font-size: fit-content;
        color: rgb(255, 126, 74);

        padding: 0.25rem 0.5rem;
        /* text-shadow: 0px 1px 0px #000; */
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border-color: #f5c499;
        border-style: solid;
        border-width: 0.2vw;
      }

      .apply:hover {
        color: rgb(224, 113, 68);
      }
      .mlh-banner-mobile {
        display: block;
        max-width:100px;
        min-width:60px;
        position:absolute;
        right: 25%;
        top: 0;
        width:10%;
        z-index:11;
      }
    }

    .links-open {
      right: 0
    }

    .dynamic {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      row-gap: 30px;
    }

    .mobile-nav {
      position: fixed;
      top: 0;
      right: 0;
      padding: 1rem;
    }

    .nav-btn {
      display: contents;
      visibility: visible;
      opacity: 1;
      color: black;
    }

    .close {
      display: block;
    }

    .open {
      display: block;
    }

    .close:hover, .open:hover {
      color: #ff592b;
    }
  }
  .mlh-banner {
    display: none;
  }

}

@media only screen and (max-width: 1000px) {
  .About {
    .who-we-are-container{
      display: flex;
      flex-direction: column;
      width: 100%;
    
    .who-we-are-child-container{
      flex: 1;
      display: inline-block;
    }

    .who-we-are-child-container p {
      max-width: 93%;
    }
    
    .logo-who-we-are{
      display: flex;
      width: 50%;
      justify-self: center;
      padding-left: 0%;
    }
  }

    .collaborators-container{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      align-items: center;
      gap: 1em;
      justify-content: center;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 750px) {
  .FAQ {
    .faq-collapse {

      padding-left: 0.7rem;
    }
    .faq-collapse-container {
      font-size: 1.1rem;
    }
  }
}

@media only screen and (max-width: 650px) {
  .banner {
    max-width: 100%;
  }

  .modalBackground {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    .titleCloseBtn {
      padding-top: 0%;
      padding-right: 0%;
      margin-right: -2%;
      margin-left: -2%;
    }
  
    .titleCloseBtn button {
      font-size: 20px;
    }

    .modalContainer {
      margin-top: auto;
      margin-bottom: auto;
      width: 60%;
      
      height: 70%;
      height:min-content;
      border-image: linear-gradient(rgb(195, 149, 210), rgb(116, 173, 207)) 27 / 1rem;
      display: flex;
      flex-direction: column;
      justify-self: center;
      padding: 10%;
      margin-left: auto;
      margin-right: auto; 
    }
  
    .apply {
      font-size: 1.8rem;
    }

  }
  .modalContainer .title {
    font-size: 0.8rem;
  }

  .modalContainer .body p {
    font-size: 1.5rem;
    color: blue;
  }
  .modalContainer .footer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin: 0;
    padding-top: 0;
  }

  .countdown-text {
    font-size: 20px;
  }

  .countdown-item {
    font-size: 20px;
  }

  .footer-container {
    font-size: 1.0rem;
    .footer-content {
      grid-template-columns: 1fr;
      .image {
        height: 6vh;
        align-self: center;
      }

      .socials {
        padding-top: 0%;
        column-gap: 0.25rem;
      }
    }  
    margin-inline: 1rem;
  }
}

@media only screen and (max-width: 700px) {
  .image-gallery-slide img {
    max-width: 100%;
    max-height: 40vh !important;
  }

  .RSVP {
    #msform fieldset {
      min-width: 100%;
    }
    .fs-title {
      font-size: 1rem;
      margin-bottom: 10px;
    }
  
    .congrats {
      color: #4da99e;
      font-size: 1rem;
    }
  
    .fs-subtitle {
      font-size: 0.8rem;
    }

    .mealOption label { 
      font-size: 70%;
    }
  }

}


/* MINIMUM */
@media only screen and (min-width: 1200px) {
  .collaborators-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    gap: 1em;
    justify-content: center;
    align-items: center;
    padding: 10% 0rem;
    align-self: center;
    height: 80%;
  }
}

@media only screen and (min-width: 950px) {
  .action-items {
    max-width: 100%;
  }
}

@media only screen and (min-width: 950px) {
  /* .tech-support{
    max
  } */
}
@media only screen and (max-width: 400px) {
  .Awards{
    h6 {
      margin-top: -0.6rem;
    }
  }
}

@media only screen and (max-width: 529px) {
  /* Schedule CSS */
  .locationTitle{
   display: none;
 }

 td.time{
   padding-bottom: 90px;
 }

 .eventDescription {
   display: block;
   font-size: 17px;
   width: 60%;
 }
 
 .eventLocation {
   display: block;
   font-family: teacher-regular;
   font-size: 16px;
   margin-top: -0.6rem;
   width: 70%;
 }


 .tab {
   font-size: 17.5px;
 }
}
